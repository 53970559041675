import { createStitches } from '@stitches/react';
import type * as StitchesTypes from '@stitches/react';

export type { VariantProps } from '@stitches/react';

import { CSSProp } from 'styled-components'; // eslint-disable-line @typescript-eslint/no-unused-vars

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicAttributes {
      css?: CSSProp;
      children?: React.ReactNode;
    }
  }
}

const stitches = createStitches({
  theme: {
    fonts: {
      firaCode: 'Fira Code, sans-serif',
      firaSans: 'Fira Sans, sans-serif',
      financier: 'Financier Display, sans-serif',
    },
    fontSizes: {
      10: '0.7500rem', // 12px
      20: '0.8750rem', // 14px
      30: '1.0000rem', // 16px
      40: '1.1250rem', // 18px
      50: '1.1880rem',
      60: '1.2500rem', // 20px
      70: '1.5000rem',
      80: '1.6000rem',
      82: '1.7500rem', // 28px
      85: '2.2500rem',
      90: '2.5000rem', // 40px
      100: '3.1250rem',
      110: '3.7500rem',
      120: '6.0000rem',
      130: '8.1250rem',
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    space: {
      1: '0.1250rem', // 2px
      2: '0.2500rem', // 4px
      3: '0.5000rem', // 8px
      4: '0.6250rem',
      5: '0.7500rem', // 12px
      6: '0.8750rem', // 14px
      7: '1.0000rem', // 16px
      8: '1.2500rem',
      9: '1.5000rem', // 24px
      10: '2.000rem', // 32px
      11: '2.5000rem', // 40px
      12: '3.0000rem', // 48px
      13: '4.0000rem',
      14: '5.0000rem',
      main: '3.0000rem',
    },
    sizes: {
      1: '0.1250rem', // 2px
      2: '0.2500rem', // 4px
      3: '0.5000rem', // 8px
      4: '0.6250rem',
      5: '0.7500rem', // 12px
      6: '0.8750rem',
      7: '1.0000rem', // 16px
      8: '1.2500rem', // 20px
      9: '1.5000rem', // 24px
      10: '1.8750rem',
      11: '2.0000rem', // 32px
      12: '2.5000rem',
      13: '3.0000rem',
      14: '4.0000rem',
      15: '5.0000rem',
    },
    lineHeights: {
      233: '0.4668rem',
      240: '0.4800rem',
      247: '0.4940rem',
      267: '0.5336rem',
      286: '0.5720rem',
      300: '0.6000rem',
      314: '0.6280rem',
      350: '0.7000rem',
      500: '1.0000rem',
      750: '1.5000rem',
    },
    letterSpacings: {
      // 300: '-0.01562em',
      // 400: '-0.00833em',
      0: '0em',
      1: '0.00735em',
      2: '0.00750em',
      3: '0.00714em',
      4: '0.00938em',
      5: '0.01071em',
      6: '0.01562em',
      7: '0.02857em',
    },
    radii: {
      1: '4px',
      2: '6px',
      3: '12px',
      4: '16px',
      round: '50%',
      pill: '9999px',
    },
    zIndices: {
      lowest: -1500,
      highest: 1500,
      aboveStdElements: 1250,
      modalBg: 1499,
      modal: 1500,
    },
    colors: {
      black250: 'rgba(3, 4, 22, 1)', // deprecated
      black400: 'rgba(2, 2, 12, 1)', // deprecated
      black500: 'rgba(0, 0, 0)', // deprecated
      white500: 'rgba(255, 255, 255, 1)', // deprecated

      tintDark8: 'rgba(195, 215, 255, 0.08)',
      tintDark16: 'rgba(195, 215, 255, 0.16)',

      tintLight8: 'rgba(174, 196, 229, 0.3)', // for use in Light mode UI
      tintLight16: 'rgba(138, 180, 242, 0.3)', // for use in Light mode UI

      gray900: '#111820',
      gray800: '#1F2937',
      gray700: '#374151',
      gray600: '#4B5563',
      gray500: '#6B7280',
      gray400: '#9CA3AF',
      gray300: '#D1D5DB',
      gray200: '#E5E7EB',
      gray100: '#F3F4F6',
      gray50: '#F9FAFB',

      red900: '#550D10',
      red800: '#77181B',
      red700: '#99262A',
      red600: '#BB383C',
      red550: '#DE4B51',
      red500: '#DD4C51',
      red400: '#E57875',
      red300: '#EB978F',
      red200: '#F3C2B2',
      red100: '#F9D5C9',
      red50: '#FFEBE4',

      orange900: '#230D00',
      orange800: '#943500',
      orange700: '#C84901',
      orange600: '#E75300',
      orange500: '#FF5C00',
      orange400: '#FF7E36',
      orange300: '#FFA675',
      orange200: '#FFCAAC',
      orange100: '#FFE8DB',
      orange50: '#FDF5F0',

      yellow900: '#5F1A06',
      yellow800: '#81370E',
      yellow700: '#99590F',
      yellow600: '#BB7B1B',
      yellow500: '#DDA12B',
      yellow400: '#FFC03F',
      yellow300: '#FFC95F',
      yellow200: '#FFD396',
      yellow100: '#FFDEBA',
      yellow50: '#FFECD7',

      green900: '#004024',
      green800: '#0D5B3A',
      green700: '#037845',
      green600: '#059A5A',
      green500: '#14B871',
      green400: '#00E582',
      green300: '#6EE7B7',
      green200: '#A7F3D0',
      green100: '#D1FAE5',
      green50: '#ECFDF5',

      cyan900: '#14535C',
      cyan800: '#146170',
      cyan700: '#0E768B',
      cyan600: '#05A4B3',
      cyan500: '#06BECB',
      cyan400: '#11D6DA',
      cyan300: '#09ECE8',
      cyan200: '#57FAF4',
      cyan100: '#B4FCFD',
      cyan50: '#EBFFFF',

      lightblue900: '#08314A',
      lightblue800: '#054466',
      lightblue700: '#035B8C',
      lightblue600: '#0284C7',
      lightblue500: '#0EA5E9',
      lightblue400: '#34BDFA',
      lightblue300: '#7DD3FC',
      lightblue200: '#BAE6FD',
      lightblue100: '#E0F2FE',
      lightblue50: '#F0F7FF',

      blue900: '#000E22',
      blue800: '#184173',
      blue700: '#025BC9',
      blue600: '#0E6EE3',
      blue500: '#227EEF',
      blue400: '#3C93FF',
      blue300: '#88BDFF',
      blue200: '#BAE6FD',
      blue100: '#DEEDFF',
      blue50: '#F0F7FF',

      purple900: '#3B2B4A',
      purple800: '#503B63',
      purple700: '#664D7D',
      purple600: '#7C6096',
      purple500: '#9373B0',
      purple400: '#AA88C9',
      purple300: '#C29EE3',
      purple200: '#DAB5FC',
      purple100: '#ECD7FF',
      purple50: '#FBF7FF',

      teal900: '#264555',
      teal800: '#395E71',
      teal700: '#427681',
      teal600: '#4A888D',
      teal500: '#53A09E',
      teal400: '#67C2B5',
      teal300: '#7CD9C9',
      teal200: '#90E8D3',
      teal100: '#93F5E3',
      teal50: '#D8FFF5',

      indigo400: '#818CF8',
    },
    shadows: {
      0: '0rem',
      1: '0px 4px 12px 4px rgba(0, 0, 0, 0.1);',
      2: '0px 4px 20px 0px rgba(41, 62, 86, 0.15);',
      3: '0px 8px 40px 0px rgba(0, 0, 0, 0.5);',
      focusRing: '0px 0px 0px 3px rgba(0, 168, 255, 0.68)',
      blueGlow: '0 0 12px 0 rgba(230, 246, 255, .3)',
    },
    transitions: {
      easeInOut: '150ms ease-in-out',
    },
  },
  media: {
    sm: '(max-width: 600px)',
    md: '(max-width: 960px)',
    lg: '(max-width: 1440px)',
    xl: '(min-width: 1441px)',
  },
  utils: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visuallyHidden: (value: boolean) => ({
      border: 0,
      clip: 'rect(0, 0, 0, 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: 1,
      wordWrap: 'normal',
    }),
  },
  prefix: '',
});

export const {
  styled,
  css,
  theme,
  getCssText,
  globalCss: stitchesGlobal,
  keyframes,
  config,
} = stitches;

export type CSS = StitchesTypes.CSS<typeof stitches>;
export type Theme = typeof config.theme;
export type resolveTokens<T extends keyof Theme> = `$${Extract<
  keyof Theme[T],
  string | number
>}`;
